<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h6>
        {{ `${$t('fields.result')} (${unMatchHistory.pagination.totalItems})` }}
      </h6>
      <b-dropdown variant="light" right class="ml-md-2">
          <template slot="button-content">
            <i class="uil uil-filter"></i>
            <span>
              ประเภทรายการ
            </span>
            <i class="uil uil-angle-down"></i>
          </template>

          <b-dropdown-item-button
            v-for="(filter, index) in filters"
            :key="index"
            :active="filterType === filter.value"
            @click="onSelectFilter(filter.value)"
            >{{ filter.name }}
          </b-dropdown-item-button>
          <b-dropdown-item-button @click="onSelectFilter('')">
            {{ $t('buttons.clear') }}
          </b-dropdown-item-button>
        </b-dropdown>
    </div>
    <!-- Table -->
    <div></div>
    <b-table
      :items="filteredList"
      :fields="fields"
      :busy="isFetching"
      responsive
      show-empty
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(transaction_time)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(transaction_code)="data">
        ({{ data.value }})
        {{ data.item.transaction_description }}
      </template>
      <template #cell(amount)="data">
        {{ data.value | currency }}
      </template>
      <template #cell(channel_code)="data">
        ({{ data.value }})
        {{ data.item.channel_description }}
      </template>

      <template #cell(is_success)="data">
        <b-badge :variant="data.value ? 'success' : 'danger'">
          {{
            data.value
              ? $t('utils.status.success_credit')
              : $t('utils.status.unsuccessful')
          }}
        </b-badge>
      </template>
      <template #cell(detail)="data">
        {{ data.value || '-' }}
      </template>
      <template #cell(id)="data">
        <div class="btn-group mb-2">
          <button
            type="button"
            class="btn btn-info btn-sm"
            @click="onViewUnMatchDetail(data.value)"
          >
            {{ $t('buttons.view') }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm"
            @click="deleteUnmatchTransaction(data.value)"
          >
            {{ $t('buttons.remove') }}
          </button>
        </div>
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #empty="">
        <p class="text-center text-muted">{{ $t('messages.nothing_here') }}</p>
      </template>
    </b-table>
    <!-- pagination -->
    <PaginationInput :per-page="limit" :total="totalRow" @update="(val) => currentPage = val"/>
    <b-modal
      v-model="isUnMatchModalActive"
      hide-header
      hide-footer
      size="lg"
    >
      <b-overlay :show="isFetchingById">
        <form>
          <b-form-group
            :label="$t('fields.issued_date')"
            label-for="name"
            label-cols-sm="12"
            label-cols-lg="3"
          >
            <b-form-input
              id="name"
              :value="unMatchHistoryDetail.transaction_time | datetime"
              readonly
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="12"
            label-cols-lg="3"
            :label="$t('bank_accounts.account_no')"
          >
            <b-form-input
              :value="unMatchHistoryDetail.account_no"
              readonly
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="12"
            label-cols-lg="3"
            :label="$t('credits.type')"
          >
            <b-form-input
              :value="
                `(${unMatchHistoryDetail.channel_code}) ${unMatchDetail.channel_description}`
              "
              readonly
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="12"
            label-cols-lg="3"
            :label="$t('fields.amount')"
          >
            <b-form-input
              :value="unMatchHistoryDetail.amount"
              readonly
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="12"
            label-cols-lg="3"
            :label="$t('bank_accounts.channel')"
          >
            <b-form-input
              :value="
                `(${unMatchHistoryDetail.transaction_code}) ${unMatchDetail.transaction_description}`
              "
              readonly
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="12"
            label-cols-lg="3"
            :label="$t('fields.remark')"
          >
            <b-form-input
              :value="unMatchHistoryDetail.remarks"
              readonly
            ></b-form-input>
          </b-form-group>
          <p>
            {{ $t('bank_accounts.detail') }}
          </p>
          <b-table-simple>
            <b-thead>
              <b-tr>
                <b-th style="width: 50px">#</b-th>
                <b-th>{{ $t('bank_accounts.bank') }}</b-th>
                <b-th>{{ $t('bank_accounts.account_name') }}</b-th>
                <b-th>{{ $t('bank_accounts.account_no') }}</b-th>
                <b-th>-</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(account, key) in unMatchHistoryDetail.detail"
                :key="key"
              >
                <b-td>
                  {{ key + 1 }}
                </b-td>
                <b-td>
                  <b-form-input
                    :value="$displayBankName(account.bank_code)"
                    readonly
                  ></b-form-input>
                </b-td>
                <b-td>
                  <b-form-input
                    :value="account.bank_name"
                    readonly
                  ></b-form-input>
                </b-td>
                <b-td>
                  <b-form-input
                    :value="account.bank_number"
                    readonly
                  ></b-form-input>
                </b-td>
                <b-td>
                  <b-overlay :show="isMatchingUnMatchHistory">
                    <b-button
                      variant="info"
                      size="sm"
                      :disabled="isMatchingUnMatchHistory"
                      @click="
                        onTransfer(unMatchHistoryDetail.id, account.user_id)
                      "
                    >
                      {{ $t('credits.deposit') }}
                    </b-button>
                  </b-overlay>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

          <div class="row d-flex justify-content-center">
            <div class="col-6 ">
              <b-input-group class="mt-3">
              <b-form-input v-model="userCodeName" placeholder="User Code Name" ></b-form-input>
              <b-input-group-append>
                <b-button variant="outline-success" @click="matchWithCodeName">เติมเงิน</b-button>
              </b-input-group-append>
            </b-input-group>
            <span class="text-small text-danger">**ถ้าหากไม่เจอรายการบัญชีให้ใส่ code name ของ user เพื่อเติมยอดนี้ให้ user</span>
            </div>
          </div>
        </form>
      </b-overlay>
    </b-modal>
    <!-- <DirectDepositModal
      ref="directDepositTransfer"
      :player="selectedUser"
      no-btn
      disabled
      @finish="onFinishTransfer"
    /> -->
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'Credit Un-Match Transactions',
  },
  components: {},
  data() {
    return {
      filterType: '',
      filters: [
        {
          name: 'ธนาคาร',
          value: 'ENET',
        },
        {
          name: 'SMS',
          value: 'SMS',
        },
        {
          name: 'TRUE WALLET (แบบ Noti)',
          value: 'TRUENOTI',
        },
      ],
      selectedTxnId: null,
      selectedUser: null,
      isUnMatchModalActive: false,
      currentPage: 1,
      limit: 50,
      offset: 0,
      onSearchTimeout: null,
      userCodeName: '',
      fields: [
        '#',
        {
          key: 'transaction_time',
          label: `${this.$t('fields.issued_date')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'account_no',
          label: this.$t('bank_accounts.account_no'),
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'transaction_code',
          label: this.$t('credits.type'),
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'amount',
          label: this.$t('fields.amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'channel_code',
          label: this.$t('bank_accounts.channel'),
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'is_success',
          label: this.$t('fields.status'),
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'remarks',
          label: this.$t('fields.remark'),
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'id',
          label: '',
          thStyle: {
            minWidth: '120px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.credit.isFetchingUnMatchHistory,
      isFetchingById: (state) => state.credit.isFetchingUnMatchHistoryById,
    }),
    ...mapGetters([
      'unMatchHistory',
      'unMatchHistoryDetail',
      'isMatchingUnMatchHistory',
      'isMaster',
      'userInfo',
      'isOwner',
    ]),
    isOwnerOrMaster() {
      return this.isOwner || this.isMaster
    },
    filteredList() {
      return this.unMatchHistory.data || []
    },
    totalRow() {
      return this.unMatchHistory?.pagination?.totalItems || 0
    },
    unMatchDetail() {
      return this.unMatchHistoryDetail || {}
    },
  },
  watch: {
    currentPage(val) {
      const nextPage = val - 1
      this.offset = nextPage * this.limit
      this.fetchData()
    },
    filterPlayerCodeName() {
      this.delaySearch()
    },
    userInfo(user) {
      if (user) {
        this.fetchData()
      }
    },
  },
  created() {
    if (typeof this.userInfo.agentId !== 'undefined') {
      this.fetchData()
    }
  },
  methods: {
    ...mapActions([
      'fetchUnMatchHistory',
      'fetchUnMatchHistoryById',
      'matchUnMatchHistory',
      'matchUnMatchHistoryByCodeName',
      'deleteUnMatchHistoryById',
      'setUnMatchFilterType'
    ]),
    fetchData() {
      this.fetchUnMatchHistory({
        limit: this.limit,
        offset: this.offset,
        agentId: this.isOwnerOrMaster ? null : this.userInfo.agentId,
      })
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onViewUnMatchDetail(unmatchedId) {
      this.selectedTxnId = unmatchedId
      this.fetchUnMatchHistoryById(unmatchedId)
      this.isUnMatchModalActive = !this.isUnMatchModalActive
    },
    onCloseModal() {
      this.selectedTxnId = null
      this.isUnMatchModalActive = false
      this.selectedUser = null
    },
    async deleteUnmatchTransaction(unmatchId) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then(async (confirm) => {
          if (confirm) {
            await this.deleteUnMatchHistoryById(unmatchId)
            await this.fetchData()
          }
        })
    },
    async matchWithCodeName(){
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then(async (confirm) => {
          if (confirm) {
            await this.matchUnMatchHistoryByCodeName({
              id: this.unMatchHistoryDetail.id,
              codeName: this.userCodeName,
            })
            this.fetchData()
            this.onCloseModal()
            this.userCodeName = '';
          }
        })
    },
    async onTransfer(unmatchId, userId) {
      await this.matchUnMatchHistory({
        id: unmatchId,
        userId: userId,
      })
      this.fetchData()
      this.onCloseModal()
    },
    // onFinishTransfer() {
    //   this.onCloseModal()
    // },
    onSelectFilter(value) {
      this.filterType = value
      this.setUnMatchFilterType({ type: value });

      this.fetchData()
    }
  },
}
</script>
